<template>
  <div class="home">
    <div class="homeContent container">
      <div class="block">
        <div class="row">
          <div class="span4">
            <div>
              <img class="img-left" src="../assets/home.jpg"/>
            </div>
            <div>
              <div class="content-heading"><h3 class="home-content-title">Azienda Agricola &nbsp </h3></div>
              <p>L'azienda agricola Giordano Emanuele nasce nel 1990 a Formignano, in provincia di Forlì-Cesena.<br/>La scelta della terra fu fatta dal padre del fondatore dell'azienda, che si trasferì su queste magnifiche colline dalla Sicilia. La scelta si rivelò ottima, la terra era fertile ed il clima favorevole, permettendo la coltivazione di ogni sorta di pianta. Emanuele intraprese questa avventura partendo dall'idea di volere offrire prodotti biologici, cresciuti con passione, sani e buoni. E ci è riuscito. Ogni giorno l'azienda ci mette tutto il suo impegno per offrire i migliori prodotti sul mercato.</p>
            </div>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="span4 revert">
            <div>
              <img class="img-right" src="../assets/le_pianazze.png"/>
            </div>
            <div>
              <div class="content-heading"><h3 class="home-content-title">Gli ingredienti &nbsp </h3></div>
              <p>Per offrire i prodotti migliori bastano pochi semplici ingredienti: Terra, Sole, Acqua e Passione. Nient'altro. Ovviamente il tutto richiede anche tanta esperienza, che Emanuele ha maturato negli anni. Tutti quelli che coltivano prodotti biologici guardano con invidia i nostri prodotti, perché nessuno riesce ad eguagliarli. Ma non ci sono segreti, solo tanta genuinità e la voglia di offrire a tutti quello che porteremmo sulla nostra tavola </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style lang="sass">
@import './Home'
</style>